/*******************Phone*****************/
@media (min-width: 300px) {
    .navbar {
        padding: 1%;
        background-color: #272727;
        max-width: 100%;
        min-height: 5%;
        z-index: 1000;
    }

    .navbar-links {
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        border: 0;

        margin: auto;
        min-height: 2%;
        align-items: center;
    }

    .navbar-links li {
        margin: 0 3%;
        padding: 2%;
        font-size: 90%;
        font-family: Gabarito;
    }

    .navbar-links a {
        text-decoration: none;
        color: #FFFFFF;
    }

    .navbar-links a:hover {
        text-decoration: none;
        color: #008CBF;
        align-items: center;
        display: flex;
    }

    .ul {
        justify-content: center;
        display: flex;
    }
}
/***************Tablette*****************/
@media (min-width: 768px) {
    .navbar {
        padding: 1%;
        background-color: #272727;
        max-width: 100%;
        min-height: 5%;
        z-index: 1000;
    }

    .navbar-links {
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        border: 0;

        margin: auto;
        min-height: 2%;
        align-items: center;
    }

    .navbar-links li {
        margin: 0 3%;
        padding: 2%;
        font-size: 150%;
        font-family: Gabarito;
    }

    .navbar-links a {
        text-decoration: none;
        color: #FFFFFF;
    }

    .navbar-links a:hover {
        text-decoration: none;
        color: #008CBF;
        align-items: center;
        display: flex;
    }

    .ul {
        justify-content: center;
        display: flex;
    }

}
/*******************PC***********************/
@media (min-width: 1024px) {
    .navbar {
        padding-top: 1%;
        padding-bottom: 1%;
        padding-right: 2%;
        background-color: #272727;
        max-width: 100%;
        min-height: 2vh;
        max-height: 2%;
    }

    .navbar-links {
        list-style: none;
        display: flex;
        justify-content: right;
        border: 0;
        padding-right: 2%;
        margin: auto;
        min-height: 1%;
        align-items: center;
    }

    .navbar-links li {
        margin: 0 3%;
        padding: 0;
        font-size: 100%;
        font-family: Gabarito;
    }

    .navbar-links a {
        text-decoration: none;
        color: #FFFFFF;
        align-items: center;
        display: flex;
    }

    .navbar-links a:hover {
        text-decoration: none;
        color: #008CBF;
        align-items: center;
        display: flex;
    }

    .ul {
        justify-content: center;
        display: flex;
    }
}