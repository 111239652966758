/*********Phone**********/
@media (min-width: 300px) {
    .content{
        width: 100vw;
        min-height: 100vh;

    }
    .realisation_titre {
        height: 10%;
        font-size: 150%;
        text-align: left;
        margin-top: 10%;
        margin-right:15%;
        margin-left: 15%;
        font-family: Gabarito;
    }

    .realisation_projets {
        display: block;
        justify-content: center;
        padding-top: 5%;
        padding-left: 15%;
        padding-right: 15%;
        padding-bottom: 10%;
    }

    .colone {
        max-width: 100%;
        padding: 1%;
    }

    .colone_haut {
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .colone_bas {
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .container_carousel {
        image-rendering: auto;
        max-width: 100%;

    }

    .imageCarousel {
        width: 100%;
        border: 0;
        margin-bottom: -2%;
    }

    .btnCarousel {
        background-color: #008CBF;
        font-size: 110%;
        color: white;
        height: 6%;
        width: 100%;
        border: 0;
        display: flex;
        text-align: center;
    }

    .nomBtn {
        padding-top: 4%;
        padding-bottom: 4%;
        min-width: 100%;
        max-height: 10%;
        font-family: Gabarito;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btngauche{
        min-width: 75%;
        font-size: 90%;
    }
    .btndroite{
        position: relative;
        min-width: 25%;
    }

    .imageplus {
        max-height: 10%;
        scale: 50%;
        position: relative;
        padding-top: 2%;
        padding-right: 2%;
        float: right;
    }
}


/**********Tablette************/
@media (min-width: 768px) {
    .content{
        max-width: 100vw;
        min-height: 100vh;
    }
    .realisation_titre {
        margin-top: 0;
        padding-top: 7%;
        padding-left: 1%;
        height: 10%;
        font-size: 250%;
        margin-right: 15%;
        margin-left: 15%;
        text-align: left;
        font-family: Gabarito;
    }

    .realisation_projets {
        margin-left: 15%;
        margin-right: 15%;
        padding: 0;
        padding-top: 5%;

        max-height: 100%;
        display: flex;
        justify-content: center;
    }

    .colone {
        width: 100%;
        padding: 1%;
    }

    .colone_haut {
        padding-bottom: 3%;
        min-width: 100%;
    }

    .colone_bas {
        padding-top: 3%;
        padding-bottom: 17%;
    }

    .container_carousel {
        image-rendering: auto;
        max-width: 200%;

    }

    .imageCarousel {
        width: 100%;
        border: 0;
        margin-bottom: -2%;
    }

    .btnCarousel {
        background-color: #008CBF;
        font-size: 125%;
        color: white;
        height: 6%;
        width: 100%;
        border: 0;
        display: flex;
        text-align: center;
    }

    .nomBtn {
        padding-top: 4%;
        padding-bottom: 4%;
        width: 80%;
        align: center;
        font-family: Gabarito;

    }
    .btngauche{
        font-size: 100%;
    }

    .imageplus {
        align: right;
    }

}

/**********PC***********/
@media (min-width: 1024px) {

    .content{
        max-width: 100%;
        min-height: 100vh;
        max-height: 100vh;
    }
    .realisation_titre {
        height: 10%;
        font-size: 300%;
        text-align: left;
        padding-top: 0;
        margin-top: 7%;
        font-family: Gabarito;
        margin-right: 20%;
        margin-left: 20%;
    }

    .realisation_projets {
        margin-left: 20%;
        margin-right: 20%;
        padding-top: 2%;
        display: flex;
        justify-content: center;
    }

    .colone {
        max-width: 100%;
        padding: 1%;
    }

    .colone_haut {
        padding-bottom: 3%;
    }

    .colone_bas {
        padding-top: 3%;
        padding-bottom: 30%;
    }

    .container_carousel {
        image-rendering: auto;
        max-width: 100%;
        max-height: 90%;

    }

    .imageCarousel {
        width: 100%;
        border: 0;
        margin-bottom: -2%;
    }

    .btnCarousel {
        background-color: #008CBF;
        font-size: 125%;
        color: white;
        height: 6%;
        width: 100%;
        border: 0;
        text-align: center;
        align-items: center;
        justify-content:center;
    }

    .nomBtn {
        padding-top: 4%;
        padding-bottom: 4%;
        min-width: 100%;
        font-family: Gabarito;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .btngauche{
    min-width: 75%;
    }
    .btndroite{
        position: relative;
        min-width: 25%;
    }

    .imageplus {
        padding-top: 2%;
        padding-right: 2%;
        align: right;
    }
}