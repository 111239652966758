/***************PHONE**************/
@media (min-width: 300px) {
    .carousel {
        position: fixed;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        min-width: 100%;
        min-height: 100%;
        z-index: 1000;
        background: transparent;
        backdrop-filter: blur(10px);
    }

    .carousel_container {
        margin: 60% auto 60%;
        justify-content: center;
        width: 100%;
        max-height: 40vh;

    }

    .carousel__btn {
        max-width: 100%;
        max-height: 100%;
        transform: translateY(-50%);
        background-color: transparent;
        color: black;
        font-size: 300%;
        border: none;

        cursor: pointer;
        z-index: 1;
        transition: background-color 0.3s;
    }

    .carousel__btn--prev {
        left: 0;
        padding-right: 20%;
        padding-left: 0;
    }

    .carousel__btn--next {
        right: 0;
        padding-right: 0;
        padding-left: 20%;
    }

    .prev{
        scale: 80%;
    }
    .next{
        scale: 80%;
    }

    .carousel__img {
        display: block;
        margin:auto;
        width: 80%;

    }
}
/****************Tablette**************/
@media (min-width: 768px) {
    .carousel {
        position: fixed;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        min-width: 100%;
        min-height: 100%;
        z-index: 1000;
        background: transparent;
        backdrop-filter: blur(10px);
    }

    .carousel_container {
        margin: 30% auto 60%;
        justify-content: center;
        width: 80%;
        max-height: 40vh;

    }

    .carousel__btn {
        max-width: 100%;
        max-height: 100%;
        transform: translateY(-50%);
        background-color: transparent;
        color: black;
        font-size: 400%;
        border: none;
        padding-right: 20%;
        padding-left: 20%;
        cursor: pointer;
        z-index: 1;
        transition: background-color 0.3s;
    }
    .prev{
       scale: 80%;
    }
    .next{
        scale: 80%;
    }

    .carousel__btn--prev {
        left: 0;
    }

    .carousel__btn--next {
        right: 0;
    }

    .carousel__img {
        display: block;
        margin:auto;
        width: 90%;

    }
}

@media (min-width: 1024px) {
    .carousel {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background: transparent;
        backdrop-filter: blur(10px);
    }

    .carousel_container {
        margin: 10% auto 10%;
        justify-content: center;
        width: 80%;
        max-height: 40vh;
    }

    .carousel__btn {
        width: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        color: black;
        font-size: 4rem;
        border: none;
        padding: 1rem;
        cursor: pointer;
        z-index: 1;
        transition: background-color 0.3s;
    }

    .carousel__btn--prev {
        left: 0;
    }

    .carousel__btn--next {
        right: 0;
    }

    .carousel__img {
        display: block;
        margin:auto;
        width: 60%;
    }
}